<template>
  <b-row>
    <b-col cols="12">
      <h2>登录密码</h2>
      <p class="hp-p1-body mb-0">
        设置新的登录密码.
      </p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row>
        <b-col cols="12" sm="8" md="7" xl="5">
          <b-form>
            <b-form-group
              label="原始密码 :"
              label-for="oldPassword"
              class="mb-24"
            >
              <b-form-input
                id="oldPassword"
                type="password"
                v-model="password"
                placeholder="请输入原始密码"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="新密码 :"
              label-for="newPassword"
              class="mb-24"
            >
              <b-form-input
                id="newPassword"
                type="password"
                v-model="newPassword"
                placeholder="请输入新密码"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="确定新密码 :"
              label-for="confirmPassword"
              class="mb-24"
            >
              <b-form-input
                id="confirmPassword"
                type="password"
                v-model="confirmPassword"
                placeholder="请再次输入新密码"
              ></b-form-input>
            </b-form-group>

            <el-button type="primary" :loading="loading" @click="setPass"> 更新密码 </el-button>
          </b-form>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data(){
    return{
      password:"",
      newPassword:"",
      confirmPassword:"",

      loading:false
    }
  },
  methods:{
    async setPass(){
      if(!this.password){
        this.$message.error('密码不能为空');
        return;
      }
      if(!this.newPassword){
        this.$message.error('新密码不能为空');
        return;
      }
      if(!this.confirmPassword){
        this.$message.error('确定密码不能为空');
        return;
      }
      if(this.confirmPassword != this.newPassword){
        this.$message.error('两次密码不一致');
        return;
      }

      this.loading = true;

      var ret = await this.$axios.post('/user_api/set_pass',{
        password:this.password,
        password_a:this.newPassword,
        password_b:this.confirmPassword
      });

      this.loading = false;

      if(ret){
        this.$message({
          message: ret.msg,
          type: ret.code == 1 ? 'success':'error'
        });
      }
    }
  }
};
</script>
